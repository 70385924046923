import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Toast from './Toast';
import CountdownTimer from './CountdownTimer';
import data from '../assets/image_data.json';
// 이미지
import mainImage from '../assets/gallery-image/main_image.jpg';
import pinIcon from '../assets/heart.png';
import phoneImage from '../assets/phone.png';
import smsImage from '../assets/sms.png';
import leafImage from '../assets/leaf.png';
import calenderImage from '../assets/calender.jpg';
import bongs from '../assets/gallery-image/img_1825.jpg';
import parks from '../assets/gallery-image/img_1789.jpg';
import deleteImg from '../assets/closeBtn.png';
import kakaoImg from '../assets/kakao.png';
import linkCopy from '../assets/linkCopy.png';
// 계좌
import brideAccountData from '../assets/bride_account_number_data.json';
import groomAccountData from '../assets/groom_account_number_data.json';
// css
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
// Modals
import ImageModal from '../components/ImageModal';
import AccountModal from '../components/accountModal';
import ContactModal from '../components/ContactModal';
import CommentModal from '../components/CommentModal';
import CommentDeleteModal from '../components/CommentDeleteModal';
// 라이브러리
import { Container as MapDiv, NaverMap, Marker, useNavermaps } from 'react-naver-maps';   // 네이버 지도

function Bride() {
  const importAll = (r) => {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  const images = importAll(require.context('../assets/gallery-image', false, /\.(png|jpe?g|svg)$/));
  const kakao = window.Kakao;
  const itemId = 'wedding-invitation'; // itemId 정의
  // 좋아요
  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLike] = useState(false)
  // state for image modal
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  // state for account modal
  const [clickedAccountData, setClickedAccountData] = useState(null);
  const [copiedAccount, setCopiedAccount] = useState(null);
  const navermaps = useNavermaps()
  // state for contact modal
  const [clickedContact, setClickedContact] = useState(null);
  // 댓글
  const [comments, setComments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const commentsPerPage = 5;

  // 댓글 달기 모달 상태
  const [clickedComment, setClickedComment] = useState(null);
  const handleCommentModal = () => {
    setClickedComment(true);
  };
  // 댓글 삭제 모달 상태
  const [clickedCommentDelete, setClickedCommentDelete] = useState(null);
  const handleCommentDeleteModal = (id) => {
    setClickedCommentDelete(id);
  };
  // Toast 팝업
  const [showToast, setShowToast] = useState(false);
  // 계좌 타이틀
  const [accountTitle, setAccountTitle] = useState('');

  const apiClient = axios.create({
    baseURL: process.env.REACT_APP_DOMAIN,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  });

  useEffect(() => {
    fetchComments();
    fetchLikes();
    initKakao();
  }, []);

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  const handleShowToast = () => {
    setShowToast(true);
  };

  // 카카오톡 공유하기 초기화
  const initKakao = () => {
    kakao.cleanup();
    if (!kakao.isInitialized()) {
      kakao.init(process.env.REACT_APP_SHARE_KAKAO_LINK_KEY);
    }
  };

  // 좋아요 수 가져오기
  const fetchLikes = async () => {
    await apiClient.get(`/api/like/${itemId}`)
      .then(response => {
        setLikes(response.data.like_count);
      })
      .catch(error => {
        setLikes(0);
        console.error('Error fetching like count:', error);
      });
  };

  // 댓글 조회
  const fetchComments = async () => {
    try {
      const response = await apiClient.get('/api/comments');
      setComments(response.data);
    } catch (error) {
      console.error('Failed to fetch comments:', error);
    }
  };

  // 페이지 변경 핸들러
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // 현재 페이지의 댓글 계산
  const indexOfLastComment = currentPage * commentsPerPage;
  const indexOfFirstComment = indexOfLastComment - commentsPerPage;
  const currentComments = comments.slice(indexOfFirstComment, indexOfLastComment);

  // 총 페이지 수 계산
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(comments.length / commentsPerPage); i++) {
    pageNumbers.push(i);
  }

  // 좋아요 증가 요청
  const handleLike = async () => {
    await apiClient.post('/api/like', { itemId })
      .then(response => {
        setLikes(response.data.like_count);
        setIsLike(true);
        fetchLikes();
      })
      .catch(error => {
        console.error('Error updating like count:', error);
      });
  };
  // 갤러리 Modal 띄우기
  const handleClick = (item, index) => {
    setCurrentIndex(index);
    setClickedImg(images[item.link]);
  };
  // 혼주에게 연락하기 Modal 띄우기
  const handleContact = () => {
    setClickedContact(true);
  };
  // 계좌 정보
  const accountClick = (account_data) => {
    setClickedAccountData(account_data.data);
    setAccountTitle(account_data.data[0].title);
  };
  // 갤러리 Modal 오른쪽 클릭
  const handleRotationRight = () => {
    const totalLength = data.data.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = images[data.data[0].link];
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = data.data.filter((item) => {
      return data.data.indexOf(item) === newIndex;
    });
    const newItem = images[newUrl[0].link];
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };
  // 갤러리 Modal 왼쪽 클릭
  const handleRotationLeft = () => {
    const totalLength = data.data.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = images[data.data[totalLength - 1].link];
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = data.data.filter((item) => {
      return data.data.indexOf(item) === newIndex;
    });
    const newItem = images[newUrl[0].link];
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  // 방명록 작성일자 포맷 YYYY.MM.DD
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월을 2자리로 맞춤
    const day = date.getDate().toString().padStart(2, '0');          // 일을 2자리로 맞춤
    return `${year}.${month}.${day}`;
  };

  // 카카오톡 공유하기
  const shareKakao = () => {
    kakao.Share.sendDefault({
      objectType: 'feed',
      content: {
        title: '전봉길 ♥ 박혜원 결혼합니다.',
        description: '8월 31일 토요일 오후 5시 아펠가모 반포',
        imageUrl: process.env.REACT_APP_DOMAIN + '/img_0810.jpg',
        link: {
          mobileWebUrl: process.env.REACT_APP_DOMAIN,
          webUrl: process.env.REACT_APP_DOMAIN
        },
      },
      buttons: [
        {
          title: '어서오세요',
          link: {
            mobileWebUrl: process.env.REACT_APP_DOMAIN,
            webUrl: process.env.REACT_APP_DOMAIN
          },
        },
      ],
    });
  };

  return (
    <div className="row">
      <div className='container'>
        {/* 소개 */}
        <div className='main-section'>
          <div className='mainsection-text'>
            <div className='mainsection-text-1'>전봉길 & 박혜원</div>
            <div className='mainsection-text-2'>
              2024년 8월 31일 토요일 오후 5시<br />
              아펠가모 반포 LL층
            </div>
          </div>

          {/* 메인 이미지 */}
          <img src={mainImage} className='mainsection-image' alt='mainsection-image' />
        </div>

        {/* 초대 문구 */}
        <div className='invitation-section'>
          <div className='invitation-section-text1'>초 대 합 니 다</div>
          <div className='invitation-section-text1-image'><img src={leafImage} /></div>
          <div className='invitation-section-text2'>
            <p>
              평생을 함께하고 싶은 사람을 만났습니다.<br />
              서로 아껴주고 사랑하며 살겠습니다.<br />
              저희 약속 위에 따듯한 마음으로 축복해 주시면<br />
              더 없는 기쁨으로 간직하겠습니다.
            </p>
          </div>

          <div className='invitation-section-image2'>
            <img src={bongs} className='invitation-section-text2-image' />
            <img src={parks} className='invitation-section-text2-image' />
            <div className='invitation-section-text3'>
              전병기・황은경<span className='text3-inner'>의 장남</span>&nbsp;&nbsp;&nbsp;봉 길
              &nbsp;&nbsp;
              <a href="tel:01074165788">
                <img src={phoneImage} className="contact-button" alt="전화하기" />
              </a>
              
              <a href="sms:01074165788">
                <img src={smsImage} className="contact-button" alt="문자하기" />
              </a>
            </div>
            <div className='invitation-section-text3'>
              박승택・이&nbsp;&nbsp;&nbsp;희<span className='text3-inner'>의 차녀</span>&nbsp;&nbsp;&nbsp;혜 원
              &nbsp;
              <a href="tel:01088038147">
                <img src={phoneImage} className="contact-button" alt="전화하기" />
              </a>
              
              <a href="sms:01088038147">
                <img src={smsImage} className="contact-button" alt="문자하기" />
              </a>
            </div>
          </div>

          {/* 혼주에게 연락하기 Modal */}
          <div className='contact-parents'>
            <div className='contact-parents-btn' onClick={() => handleContact()}>혼주에게 연락하기</div>
          </div>
          {clickedContact && <ContactModal setClickedContact={setClickedContact} />}
        </div>

        {/* 달력 및 D-Day */}
        <div className='calender-section'>
          <div className='calender-section-text'>2024.08.31<br />토요일 오후 5시</div>
          <hr className="calender-seperator-line" />
          <div className='calender-section-image'>
            <img src={calenderImage} style={{ width: '320px', height: '230px' }} />
          </div>
          <hr className="calender-seperator-line" />
          <CountdownTimer />
        </div>

        {/* 갤러리 */}
        <div className='gallery-section'>
          <div className='gallery-section-text'>갤 러 리</div>
        </div>
        <div className='gallery-image-list-wrapper'>
          {data.data.map((item, index) => (
            <div key={index} className='col-3'>
              <img className='gallery-image'
                src={images[item.thumb_image_link]}
                alt={item.text}
                onClick={() => handleClick(item, index)} />
            </div>
          ))}
        </div>
        {clickedImg && <ImageModal
          clickedImg={clickedImg}
          handleRotationRight={handleRotationRight}
          handleRotationLeft={handleRotationLeft}
          setClickedImg={setClickedImg}
        />}

        {/* 네이버 지도 */}
        <div className='location-section'>
          <div className='location-section-text1'>오 시 는&nbsp;&nbsp;길</div>
        </div>

        {/* 웨딩홀 위치 */}
        <div className='location-info-section'>
          <div className='location-info-section-text1'>
            아펠가모 반포점 LL층<br />
            서울 서초구 반포대로 235 LL층<br />
            Tel. 02-3431-0230
          </div>

          <div className='location-map-section'>
            <MapDiv style={{ width: '100%', height: '350px' }}>
              <NaverMap
                defaultCenter={new navermaps.LatLng(37.50069999999999, 127.003259)}
                defaultZoom={16}>
                <Marker
                  position={new navermaps.LatLng(37.50069999999999, 127.003259)}
                  icon={{
                    url: pinIcon,
                    size: new navermaps.Size(50, 30)
                  }} />
              </NaverMap>
            </MapDiv>
          </div>
        </div>

        <div className='location-how-publictrans-section'>
          <div className='location-how-publictrans-section-text1'>지 하 철</div>
          <div className='location-how-publictrans-section-list'>
            <span style={{ fontWeight: '700' }}>
              <font color='#ECAB5C'>3호선</font>,&nbsp;
              <font color='#316520'>7호선</font>,&nbsp;
              <font color='#AF966A'>9호선</font><br />
            </span>
            고속터미널역 5번 출구 방향으로 나와<br />
            서래공원 방면 신호등 건넌 후 첫번째 건물
          </div>
        </div>

        <div className='location-how2-section'>
          <div className='location-how2-section-text1'>버 스</div>
          <div className='location-how2-section-text2'>
            <span style={{ fontWeight: '700' }}>서울지방조달청 또는 서울성모병원</span> 정류장 하차<br />
            <font color='#3D80DF'>간선</font> : 405, 740<br />
            <font color='#37792F'>지선</font> : 5413<br />
            <font color='#316520'>마을</font> : 서초13, 서초14, 서초21<br />
          </div>
        </div>

        <div className='location-how2-section'>
          <div className='location-how2-section-text1'>자 가 용</div>
          <div className='location-how2-section-text2'>
            <span style={{ fontWeight: '700' }}>내비게이션</span>에 다음과 같이 검색 해주세요.<br />
            - 아펠가모 반포<br />
            - (지번) 서울시 서초구 반포동 63-7 LL층<br />
            - (도로명) 서울시 서초구 반포대로 235 LL층<br /><br />
            웨딩홀 주차장 2시간 무료
          </div>
        </div>

        {/* 마음 전하실 곳 */}
        <div className='congratulatory-section'>
          <div className='congratulatory-section-title'>
            마 음&nbsp;&nbsp;전 하 실&nbsp;&nbsp;곳
          </div>
          <div className='congratulatory-section-space' />
          <div className='congratulatory-section-thanks-comment'>
            참석이 어려워 직접 축하를 전하지 못하는<br />
            분들을 위해 계좌번호를 기재하였습니다.<br />
            넓은 마음으로 양해 부탁드립니다.<br />
            전해주시는 마음은 소중하게 간직하여<br />
            좋은 부부의 모습으로 보답하겠습니다.
          </div>

          <div className='congratulatory-section-buttons'>
            <div
              className='congratulatory-section-btn'
              onClick={() => accountClick(groomAccountData)}>
              신랑측 계좌번호
            </div>
            <div className='congratulatory-section-space' />
            <div
              className='congratulatory-section-btn'
              onClick={() => accountClick(brideAccountData)}>
              신부측 계좌번호
            </div>
          </div>
        </div>
        {clickedAccountData && <AccountModal
          clickedAccountData={clickedAccountData}
          setClickedAccountData={setClickedAccountData}
          copiedAccount={copiedAccount}
          setCopiedAccount={setCopiedAccount}
          accountTitle={accountTitle}
          setAccountTitle={setAccountTitle}
        />}

        {/* 방 명 록 */}
        <div className='guest-book-section'>
          <div className='guest-book-section-title'>방 명 록</div>
        </div>

        <div>
          <div class="guestbook-wrap">
            <div class="c-guestbook gothic">
              <div class="comments">
                <div class="item white">
                  {currentComments.length > 0 ? (
                    currentComments.map((comment) => (
                      <div key={comment._id} className="comment">
                        <div class="close">
                          <span class="date">{formatDate(comment.date)}</span>
                          <span class="icon">
                            <button className='delete-button'
                              onClick={() => handleCommentDeleteModal(comment._id)}>
                              <img src={deleteImg} style={{ width: '11px', height: '10px' }} />
                            </button>
                            {clickedCommentDelete &&
                              <CommentDeleteModal
                                commentId={clickedCommentDelete}
                                fetchComments={fetchComments}
                                setClickedCommentDelete={setClickedCommentDelete} />}
                          </span>
                        </div>
                        <div class="name"><strong>{comment.username}</strong></div>
                        <div class="text">{comment.content}</div>
                      </div>
                    ))
                  ) : (<p align='center' style={{fontSize: '17px', fontFamily: 'Nanum_Restart'}}>댓글이 없습니다.</p>)}
                </div>
              </div>
              {/* 1 2 3 */}
              <div className="pagination">
                {pageNumbers.map(number => (
                  <button key={number} onClick={() => handlePageChange(number)}>
                    {number}
                  </button>
                ))}
              </div>
              {/* 방명록 남기기 */}
              <div className='comment-form' onClick={() => handleCommentModal()}>방명록 남기기</div>
              {clickedComment &&
                <CommentModal
                  fetchComments={fetchComments}
                  setClickedComment={setClickedComment} />}
            </div>
          </div>
        </div>

        {/* 소 식 공 유 */}
        <div className='share-news-section'>
          <div className='share-news-section-title'>소 식 공 유</div>

          {/* 카카오 공유하기 */}
          <div className='kakao-button'>
            <button onClick={() => shareKakao()}>
              <img src={kakaoImg} />
              <font color='#000000'>카카오톡 공유하기</font>
            </button>
          </div>

          {/* 링크주소 복사하기 */}
          <CopyToClipboard
            text={process.env.REACT_APP_DOMAIN}
            onCopy={() => process.env.REACT_APP_DOMAIN}>
            <div className='copy-link' onClick={handleShowToast}>
              <img src={linkCopy} />
              링크주소 복사하기
            </div>
          </CopyToClipboard>

          <div>
            <Toast message="링크 주소가 복사되었습니다." visible={showToast} />
          </div>

          {/* 좋아요 버튼 */}
          <div className='like-button'>
            <button onClick={handleLike}>
              {isLiked ?
                <div className='like-on'>♥</div> :
                <div className='like-off'>♡</div>}
            </button>
          </div>

          {/* 좋아요 수 */}
          <div className='like-count'>{likes}</div>

          {/* 감사 인사 */}
          <div className='thank-you-for-all'>
            감사합니다♥<br />
            Copyright 2024 <span style={{ fontWeight: '700' }}>Jeon&Park.</span> All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bride;
