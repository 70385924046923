import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Bride from "./pages/Bride";
import FloatingButton from './components/FloatingButton';

const App = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <div>
      <Routes>
        <Route path="/bride" element={<Bride />} />
        <Route path="*" element={<Bride />} />
      </Routes>
      {showButton && <FloatingButton onClick={handleClick} />}
    </div>
  );
};

export default App;