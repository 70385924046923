import React from 'react';
import './FloatingButton.css';
import topButton from '../assets/top-button.png';

const FloatingButton = ({ onClick }) => {
  return (
    <button className="floating-button" onClick={onClick}>
      <img src={topButton} className='floating-image'/>
    </button>
  );
};

export default FloatingButton;