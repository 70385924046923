import { CopyToClipboard } from 'react-copy-to-clipboard';
import copyAccountImg from '../assets/copyAccount.jpg';

const accountModal = ({ clickedAccountData, setClickedAccountData, copiedAccount, setCopiedAccount, accountTitle, setAccountTitle }) => {
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const accountClick = (e) => {
        if (e.target.classList.contains("dismiss")) {
            setClickedAccountData(null);
            setCopiedAccount(null);
        }
    };

    const copyAccountNumber = async (account_number) => {
        setCopiedAccount(account_number);
        await delay(1000);
        setCopiedAccount(null);
    };

    return <>
        <div className="overlay dismiss" onClick={accountClick}>
            <div className="account-popup">
                <div className="account-info-title">{accountTitle}</div>
                {clickedAccountData.map((item, index) => (
                    <div key={index} className="account-info-each">
                        <div className="each-body">
                            <p className="each-account-text">
                                {item.name}&nbsp;
                                <span style={{ fontWeight: '700' }}>
                                    {item.account_owner}
                                </span><br />
                                {item.bank_name} {item.account_number}
                            </p>
                            <CopyToClipboard
                                text={item.account_number}
                                onCopy={() => copyAccountNumber(item.account_number)}>
                                <div className="each-copy-btn">
                                    <img src={copyAccountImg} style={{width: '15px', height: '15px'}} />
                                    복사
                                </div>
                            </CopyToClipboard>
                        </div>
                        {copiedAccount === item.account_number &&
                            <div className="copy-success">복사되었습니다.</div>}
                        {index != clickedAccountData.length - 1 &&
                            <hr className="each-line" />}
                    </div>
                ))}
                <div className="account-popup-close dismiss"
                    onClick={accountClick}>닫기</div>
            </div>
        </div>
    </>
}

export default accountModal;