import React, { useEffect } from 'react';
import phoneGroom from '../assets/phoneGroom.png';
import phoneBride from '../assets/phoneBride.png';
import smsGroom from '../assets/smsGroom.png';
import smsBride from '../assets/smsBride.png';
import './ContactModal.css';

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e) {
    e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
    window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; }
    }));
} catch (e) { }

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
// call this to Disable
function disableScroll() {
    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}
// call this to Enable
function enableScroll() {
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener('touchmove', preventDefault, wheelOpt);
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

const ContactModal = ({ setClickedContact }) => {
    const handleClick = (e) => {
        if (e.target.classList.contains('dismiss')) {
            setClickedContact(null);
        }
    };

    useEffect(() => {
        disableScroll();
        return () => enableScroll();
    }, []);

    return (
        <div className="overlay dismiss" onClick={handleClick}>
            <div className="contact-buttons-wrapper">
                <div className="contact-parents-title">혼주에게 연락하기</div>
                <div className='contact-space' />

                <div className="contact-section">
                    <div className="contact-icons">
                        <div className="contact-section-title">
                            <div><font color='#498CB9'>신랑 아버지</font></div>
                            <div>전병기</div>
                            <div className='phone-numbers'>
                                <a href="tel:01047144418">
                                    <img src={phoneGroom} className="contact-button" alt="전화하기" />
                                </a>
                                <a href="sms:01047144418">
                                    <img src={smsGroom} className="contact-button" alt="문자하기" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contact-icons">
                    <div className="contact-section-title">
                        <div><font color='#498CB9'>신랑 어머니</font></div>
                        <div>황은경</div>
                        <div className='phone-numbers'>
                            <a href="tel:01029055788">
                                <img src={phoneGroom} className="contact-button" alt="전화하기" />
                            </a>
                            <a href="sms:01029055788">
                                <img src={smsGroom} className="contact-button" alt="문자하기" />
                            </a>
                        </div>
                    </div>
                </div>

                <hr className="seperator-line" />

                <div className="contact-section">
                    <div className="contact-icons">
                        <div className="contact-section-title">
                            <div><font color='#A96B6B'>신부 아버지</font></div>
                            <div>박승택</div>
                            <div className='phone-numbers'>
                                <a href="tel:01023908177">
                                    <img src={phoneBride} className="contact-button" alt="전화하기" />
                                </a>
                                <a href="sms:01023908177">
                                    <img src={smsBride} className="contact-button" alt="문자하기" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="contact-icons">
                        <div className="contact-section-title">
                            <div><font color='#A96B6B'>신부 어머니</font></div>
                            <div>이&nbsp;&nbsp;&nbsp;희</div>
                            <div className='phone-numbers'>
                                <a href="tel:01036668147">
                                    <img src={phoneBride} className="contact-button" alt="전화하기" />
                                </a>
                                <a href="sms:01036668147">
                                    <img src={smsBride} className="contact-button" alt="문자하기" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='contact-space' />
            </div>
        </div>
    );
};

export default ContactModal;