import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { ReactComponent as LeftArrow } from '../assets/arrow-left.svg';
import { ReactComponent as RightArrow } from '../assets/arrow-right.svg';
import '../components/ImageModal.css';

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e) {
    e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
    window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; }
    }));
} catch (e) { }

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
function disableScroll() {
    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
function enableScroll() {
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener('touchmove', preventDefault, wheelOpt);
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

const ImageModal = ({ clickedImg, handleRotationRight, handleRotationLeft, setClickedImg }) => {
    const [direction, setDirection] = useState(null);

    const handleSwipe = (dir) => {
        setDirection(dir);
        if (dir === 'left') {
            handleRotationRight();
        } else {
            handleRotationLeft();
        }
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => handleSwipe('left'),
        onSwipedRight: () => handleSwipe('right'),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    useEffect(() => {
        if (direction) {
            const timer = setTimeout(() => setDirection(null), 300);
            return () => clearTimeout(timer);
        }
    }, [direction]);

    useEffect(() => {
        // modal이 떠 있을 땐 스크롤 막음
        disableScroll();
       
        // modal 닫히면 다시 스크롤 가능하도록 함
        return () => enableScroll();
      }, []);

    const handleClick = (e) => {
        if (e.target.classList.contains('dismiss')) {
            setClickedImg(null);
        }
    };

    return (
        <div className={`overlay dismiss ${direction}`} onClick={handleClick} {...swipeHandlers}>
            <img className="modal-image" src={clickedImg} alt="original size" />
            <span className="dismiss close-button" onClick={handleClick}>X</span>
            <div className="overlay-arrows overlay-arrows_right" onClick={() => handleSwipe('left')}>
                <RightArrow width="50" height="50" />
            </div>
            <div className="overlay-arrows overlay-arrows_left" onClick={() => handleSwipe('right')}>
                <LeftArrow width="50" height="50" />
            </div>
        </div>
    );
};

export default ImageModal;
