import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CommentModal.css';

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
function preventDefault(e) {
    e.preventDefault();
}
function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
}
// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
    window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; }
    }));
} catch (e) { }
var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
// call this to Disable
function disableScroll() {
    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}
// call this to Enable
function enableScroll() {
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener('touchmove', preventDefault, wheelOpt);
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

const CommentModal = ({ fetchComments, setClickedComment }) => {
    const [userName, setUserName] = useState('');
    const [content, setContent] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    // axios
    const apiClient = axios.create({
        baseURL: process.env.REACT_APP_DOMAIN,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        },
    });
    // 댓글 생성
    const handleCreate = async () => {
        if (!userName || !content || !password) {
            setErrorMessage('성함, 비밀번호, 방명록을 모두 입력 부탁드립니다.');
            return;
        }
        await apiClient.post('/api/comments', {
            username: userName,
            content: content,
            password: password,
            date: Date.now
        }).then(response => {
            console.log('댓글 생성');
        }).catch(error => {
            console.error('Error editing comment:', error);
        });
        setUserName('');
        setContent('');
        setPassword('');
        fetchComments();
        setClickedComment(false);
    };

    const handleClick = (e) => {
        if (e.target.classList.contains('dismiss')) {
            setClickedComment(null);
        }
    };

    useEffect(() => {
        // modal이 떠 있을 땐 스크롤 막음
        disableScroll();
        // modal 닫히면 다시 스크롤 가능하도록 함
        return () => enableScroll();
    }, []);

    return (
        <div className="overlay dismiss" onClick={handleClick}>
            <div class="v--modal-box v--modal vue-dialog c-guestbook-modal"
                style={{ top: '206px', left: '29px', width: '338.3px', height: 'auto' }}>
                <div class="c-guestbook-header">
                    방명록
                    <div class="btn-close dismiss" onClick={handleClick} />
                </div>
                <div onselectstart="return false" class="c-guestbook-modal-wrap">
                    <div class="c-guestbook-modal-inner">
                        <table>
                            <tbody>
                                <tr>
                                    <th>성함</th>
                                    <td>
                                        <input
                                            type="text"
                                            placeholder="성함 입력"
                                            maxlength="12"
                                            value={userName}
                                            onChange={(e) => setUserName(e.target.value)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>비밀번호</th>
                                    <td>
                                        <input
                                            type="password"
                                            placeholder="비밀번호 입력"
                                            maxlength="15"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <textarea
                                            type="text"
                                            placeholder="방명록을 남겨주세요."
                                            value={content}
                                            onChange={(e) => setContent(e.target.value)}
                                            maxlength="100" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button class="btn-submit" onClick={handleCreate}>작성하기</button>
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommentModal;