import React from 'react';
import styled, { keyframes, css } from 'styled-components';

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const ToastContainer = styled.div`
  position: fixed;
  bottom: 110px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-family: 'Nanum_Restart';
  width: 70%;
  z-index=100;
  text-align: center;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  ${(props) =>
    !props.visible &&
    css`
      animation: ${fadeOut} 1s forwards;
    `}
  transition: opacity 0.5s;
`;

const Toast = ({ message, visible }) => {
  if (!visible) return null;
  return (
    <ToastContainer visible={visible}>
      {message}
    </ToastContainer>
  );
};

export default Toast;