import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CommentDeleteModal.css';
import btnClose from '../assets/closeBtn.png';

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
function preventDefault(e) {
    e.preventDefault();
}
function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
}
// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
    window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; }
    }));
} catch (e) { }
var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
// call this to Disable
function disableScroll() {
    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}
// call this to Enable
function enableScroll() {
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener('touchmove', preventDefault, wheelOpt);
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

const CommentDeleteModal = ({ commentId, fetchComments, setClickedCommentDelete }) => {
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    // axios
    const apiClient = axios.create({
        baseURL: process.env.REACT_APP_DOMAIN,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        },
    });
    // 댓글 삭제
    const handleDelete = async () => {
        if (!password) {
            setErrorMessage('비밀번호 입력 부탁드립니다.');
            return;
        }
        await apiClient.delete(`api/comments/${commentId}`, {
            data: { password }
        }).then(response => {
            console.log('댓글 삭제 성공');
        }).catch(error => {
            console.error('Error editing comment:', error);
        });
        fetchComments();
        setClickedCommentDelete(false);
    };

    const handleClosePopup = (e) => {
        if (e.target.classList.contains('dismiss')) {
            setClickedCommentDelete(null);
        }
    };

    useEffect(() => {
        // modal이 떠 있을 땐 스크롤 막음
        disableScroll();
        // modal 닫히면 다시 스크롤 가능하도록 함
        return () => enableScroll();
    }, []);

    return (
        <div className="delete-overlay dismiss" onClick={handleClosePopup}>
            <div class="v--modal-box v--modal vue-dialog c-guestbook-delete-modal"
                style={{ top: '317px', left: '86px', width: '338px', height: 'auto' }}>
                <div class="c-guestbook-header-delete">
                    방명록 삭제
                    <div class="btn-close-delete dismiss" onClick={handleClosePopup}>
                        <img src={btnClose}
                            style={{ width: '11px', height: '10px' }} />
                    </div>
                </div>
                <div onselectstart="return false" class="c-guestbook-modal-wrap">
                    <div class="c-guestbook-modal-inner">
                        <table>
                            <tbody>
                                <tr>
                                    <th>비밀번호</th>
                                    <td>
                                        <input
                                            type="password"
                                            placeholder="비밀번호 입력"
                                            maxlength="15"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button class="btn-submit" onClick={handleDelete}>삭제하기</button>
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommentDeleteModal;