import React, { useState, useEffect } from 'react';
import '../App.css';

const CountdownTimer = () => {
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    const updateCountdown = () => {
      const targetDate = new Date('2024-08-31T17:00:00');
      const now = new Date();
      const diff = targetDate - now;

      if (diff <= 0) {
        setCountdown('D-Day');
        clearInterval(timer);
        return;
      }
      const msInDay = 24 * 60 * 60 * 1000;
      const daysLeft = Math.ceil(diff / msInDay);
      setCountdown(`D-${String(daysLeft).padStart(2, '0')}`);
    };

    updateCountdown();
    const timer = setInterval(updateCountdown, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className='countdown-timer'>
      봉길 <span style={{ color: '#DF0D0D' }}>♥</span> 혜원의 결혼식이 <span style={{ color: '#A96B6C' }}>{countdown}일</span> 남았습니다.
    </div>
  );
};

export default CountdownTimer;
